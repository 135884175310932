import { Injectable } from '@angular/core';
import { fetchAndActivate, RemoteConfig, getValue } from '@angular/fire/remote-config';

@Injectable({
  providedIn: 'root',
})
export class RemoteConfigService {
  constructor(private remoteConfig: RemoteConfig) {
    this.loadRemoteConfig();
  }

  async loadRemoteConfig() {
    try {
      this.remoteConfig.settings.minimumFetchIntervalMillis = 0;
      await fetchAndActivate(this.remoteConfig);
      console.log("Remote Config Fetched");
    } catch (error) {
      console.error("Remote Config Fetch Failed:", error);
    }
  }

  getFeatureFlag(flag: string): boolean {
    const value = getValue(this.remoteConfig, flag).asString(); // Get as string
    const booleanValue = value === 'true'; // Convert manually
    console.log("Feature Flag Value:", flag, value, booleanValue);
    return booleanValue;
  }
}
